:root {
  --primary-color: 15, 194, 192;
}

.home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
  overflow: hidden;
}

.big-circle {
  position: absolute;
  width: 50vw;
  height: 50vw;
  min-width: 350px;
  top: -128px;
  left: -10vw;
  border-radius: 50%;
  background: rgba(46, 87, 202, 0.619);
  filter: blur(100px);
  z-index: 0;
}


.home-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.text-content {
  flex: 1;
}

.text-content h1 {
  font-size: 3rem;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 33px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.text-content p {
  font-size: 1.5rem;
}

.image-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  flex: 1;
}

.image-wrapper {
  position: relative;
  width: 80%; 
  max-width: 400px; 
  overflow: visible;
}

.image-wrapper::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 3px dashed #5e99ff;
  opacity: 60%; 
  background-clip: padding-box;
  border-radius: 50%;
  animation: dash-animation 80s linear infinite;
}

.image-wrapper img {
  display: block;
  width: 100%; 
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

@keyframes dash-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-icons {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
}

.iconrec {
  position: relative;
  display: inline-block;
  margin: 20px 10px 0 5px;
  padding: 5px 0;
}

.iconrec a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  z-index: 100; 
}

.iconrec::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background-color: rgba(255, 255, 255, 0.1); 
  border-radius: 5px; 
  transition: box-shadow 0.3s ease;
}

.iconrec:hover::before {
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px; 
}

@media(max-width: 890px) {
  .home {
    flex-direction: column;
    align-items: center;
  }

  .text-content, .image-content {
    flex: 1 1 100%;
    text-align: center;
  }
  .image-content {
    order: 1;
  }

  .text-content {
    order: 2;
    margin-top: 3rem;
  }
  .social-icons{
    justify-content: center;
  }

  .image-wrapper {
    width: 80%; 
  }
}


