.contact-section {
    


  }
  

  
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #45708a21;
    border-radius: 8px;
    max-width: 800px;
    margin: 5rem auto 10rem auto;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .form-group.full-width {
    flex: 1 0 100%;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input.form-input,
  textarea.form-textarea {
    background-color: #012030;
    color: #ffffff;
    border: 0.1px solid #07415f;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    outline: none;
  }
  
  textarea.form-textarea {
    height: 100px;
  }
  
  .form-row.center {
    justify-content: center;
  }
  
  .submit-button {
    background-color: #07415f;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #055f8f;
  }
  

  @media (max-width:860px) {
    .form-row{
        flex-direction: column;
    }
  }