.about-section {
    padding: 100px 5vw;
   
}

.about-container{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5%;
}
.about-text{
  text-align: center;
  font-size: 24px;
  margin-bottom: 50px;
}

.rectangle-about {
  /* z-index: 10000; */

  /* height: 6rem; */
  background-color: rgb(23, 53, 87);
  border-radius: 15px;
  border: 1px solid rgb(54, 101, 154);
  padding: 0px  10px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  box-shadow: 10px 10px 33px 11 rgba(100, 199, 255, 1) !important; 
  
  
}
.icon {
  /* position: absolute; */
  width: 50px;
  height: 50px;
  filter: brightness(0) invert(1);
  
}

.rectangle-about:hover{
  scale: 1.02;
  transition-duration: 300ms;
  animation: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


@media(max-width: 890px) {
  .about-container{
    flex-direction: column;
  }
  
}