.projects-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-direction: column;
    margin-bottom: 6rem;
}

.wrapper {
    max-width: 1100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
}

.title {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
}

.desc {
    font-size: 18px;
    max-width: 550px;
    text-align: center;
    color: rgb(173, 173, 173);
}

.toggle-group {
    display: flex;
    border: 1.5px solid rgb(66, 85, 139);
    color: white;
    font-size: 16px;
    border-radius: 12px;
    font-weight: 500;
    margin: 22px 0;
    position: relative;
    overflow: hidden;
    
}

.toggle-group::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 33.33%;
    background-color: rgba(66, 85, 139, 0.55);
    transition: left 0.3s ease;
    z-index: -1;
}

.toggle-group.all-selected::before {
    left: 0;
}

.toggle-group.mobile-selected::before {
    left: 33.33%;
}

.toggle-group.web-selected::before {
    left: 66.66%;
}

.toggle-button {
    padding: 8px 18px;
    cursor: pointer;
    flex: 1;
    text-align: center;
}
.toggle-group.all-selected{
    width: 33%;
}
.toggle-group.mobile-selected{
    width: 33%;
}
.toggle-group.web-selected{
    width: 33%;
}

.divider {
    width: 1.5px;
    background-color: rgb(66, 85, 139);
}


.projects-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 1100px;
    justify-content: center;
    
}
.project-card{
    display: flex;
    flex-direction: column;
    width: 330px;
    /* height: 420px; */
    background-color: #012030;
   
    border-radius: 15px;
    border: 1px solid #07415f;
    transition:  0.3s ease;
    overflow: hidden;
}
.project-card:hover{
   transform: rotate(-2deg);
   scale: 1.01;
}
.project-card .upper img{
    width: 100%;
    /* height: 200px; */
}
.tags-list{
    display: flex;
    padding: 8px 16px;
    gap: 15px;
    flex-wrap: wrap;
}

.tag{
    padding: 8px 12px;
    background-color: #223843ce;
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.7);
}
.project-title{
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 ;
    padding-left: 16px;
}

.project-desc{
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    padding-left: 16px;
    max-width: 80%;
    text-align: start;
}
.links-row{
    display: flex;
    flex-direction: row;
    justify-content: space-betweens;
    align-items: center;
    padding: 10px 16px;
}
.github{
    color: rgb(255, 255, 255);
    
    opacity: 0.5;
    transition: 0.3s ease;
    
}
.github .giticon{
    height: 25px;
    width: 25px;
    margin: 15px 0 ;
}
.github:hover{
    opacity: 1;
}

.video-view{
    color: white;
    text-decoration: none;
    flex-grow: 1;
    text-align: end;
    opacity: 0.5;
    font-weight: 600;
    font-size: 14px;
    transition: 0.3s ease;
    cursor: pointer;
}

.video-view:hover{
    opacity: 1;
    font-size: 15px;
    text-decoration: underline;
}

@media (max-width: 890px) {
    .toggle-group {
        font-size: 14px;
        width: 80%;
        
    }

    .toggle-button {
        padding: 6px 8px;

        width: 33%;
    }
    .toggle-group.all-selected{
        width: 80%;
    }
    .toggle-group.mobile-selected{
        width: 80%;
    }
    .toggle-group.web-selected{
        width: 80%;
    }
    
}
