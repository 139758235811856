.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0; /* Start with opacity 0 */
  animation: fadeIn 0.3s forwards; /* Add fade-in animation */
}

@keyframes fadeIn {
  to {
    opacity: 1; /* End with opacity 1 */
  }
}

.modal-content {
  background-color: #012030;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
  opacity: 0; /* Start with opacity 0 */
  animation: fadeInContent 0.3s forwards 0.3s; /* Add fade-in animation for content */
}

@keyframes fadeInContent {
  to {
    opacity: 1; /* End with opacity 1 */
  }
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 24px;
  cursor: pointer;
}

iframe{
  width:100%;
  height:315px
}

@media (max-width: 700px) {
  iframe{
    width: 100%;
    height: 200px;
  }
  .modal-content{
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}