.skills-section{
    /* margin-top: 5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-direction: column;

    margin-bottom: 6rem;
}

.wrapper{
   max-width: 1100px;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   gap: 12px;

}
.skills-title{
    font-size: 42px;
    font-weight: bold;
    text-align: center;
}
.skills-desc{
    font-size: 18px;
    max-width: 80%;
    text-align: center;
    color: rgb(173, 173, 173);

}

.skills-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: center;
    gap:30px;
}
.skill{
    width: 300px;
    height: 300px;
    background-color: #012030;
    border: 0.1px solid #07415f;
    border-radius: 16px;
    padding: 18px 36px;
}

.skill-title{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    color: rgb(213, 221, 223);
    text-align: center;

}

.skills-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
}

.skill-item{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #cecece;
    padding: 10px 16px;
    font-weight: 400;
}

.skill-item img{
    width: 20px;
    height:20px;
    
}


/* Responsive */

@media (max-width:890px) {
    .skill{
        max-width: 400px;
        padding: 10px 36px;
    }
    .skill-item{
        font-size: 16px;
        padding: 8px 12px;
    }
}
@media (max-width:500px) {
    .skill{
        max-width: 330px;
        padding: 10px 36px;
    }
    
}